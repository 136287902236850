export enum PosthogGroupTypes {
    Company = 'company',
    Office = 'office',
}

export enum PosthogTransferDateSource {
    WaterTransferRealtorDashboard = 'Water Transfer Realtor Dashboard',
    BoilerMaintenanceTransactionDetails = 'Boiler Maintenance Transaction Details',
    MoverModal = 'Mover Modal',
    LeaverDashboard = 'Leaver Dashboard',
}

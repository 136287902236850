import { Injectable } from '@angular/core';
import { RealEstateGroup } from '@app/real-estate-group/interfaces/real-estate-group.interface';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { PosthogGroupTypes } from '@app/tracking/enums/posthog.enum';
import { AuthUser, DbUtils, Me, PostHogEventsEnum } from '@smooved/core';
import posthog from 'posthog-js';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { zip } from 'rxjs';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { take } from 'rxjs/operators';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { ServiceType } from '@app/services/types/service-type';

@Injectable({ providedIn: 'root' })
export class PosthogService {
    constructor(
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public identify(id: string, user: AuthUser, extraProperties = {}): void {
        posthog.identify(id, {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            ...extraProperties,
        });
    }

    public resetIdentity(): void {
        posthog.reset();
    }

    public groupByCompany(isAdmin: boolean, realEstateGroup?: RealEstateGroup): void {
        if (isAdmin) {
            this.group(PosthogGroupTypes.Company, 'admin');
            return;
        }
        this.group(PosthogGroupTypes.Company, DbUtils.getStringId(realEstateGroup), {
            name: realEstateGroup.name,
        });
    }

    public groupByOffice(officeId: string, realEstateGroup: RealEstateGroup): void {
        if (!officeId) return;
        const officeName = realEstateGroup.offices.find((office) => DbUtils.getStringId(office) === officeId)?.name;
        this.group(PosthogGroupTypes.Office, officeId, { name: officeName });
    }

    private group(type: PosthogGroupTypes, id: string, properties?: Object): void {
        posthog.group(type, id, properties);
    }

    public sendEventForTransactionState(event: PostHogEventsEnum, extra = {}): void {
        this.moveSandbox.moveOnce$.subscribe((move) => {
            const transactionId = DbUtils.getStringId(move);
            this.sendEventForTransaction(event, transactionId, extra);
        });
    }

    public sendEventForTransaction(event: PostHogEventsEnum, transactionId: string, extra = {}): void {
        if (!event) return;
        zip(this.realEstateGroupSandbox.realEstateGroupOnce$, this.authenticationSandbox.roleOnce$).subscribe(([realEstateGroup, role]) => {
            posthog.capture(event, {
                ...extra,
                transactionId,
                role,
                realEstateGroupId: DbUtils.getStringId(realEstateGroup),
                officeId: (realEstateGroup as unknown as Me)?.officeId,
                realEstateAgentId: (realEstateGroup as unknown as Me)?.realEstateAgentId,
            });
        });
    }

    public clickContactUs(contactType: string): void {
        if (!contactType) return;
        posthog.capture(PostHogEventsEnum.ClickContactUs, {
            contactType,
        });
    }

    /**
     * Since we are still using the shopping cart mechanism right now, this function will loop over the items and send to Posthog
     * @private
     */
    public sendForShoppingCart(event: PostHogEventsEnum): void {
        this.moveSandbox.shoppingCart$.pipe(take(1)).subscribe((shoppingCart) => {
            for (const item of shoppingCart || []) {
                switch (item.type) {
                    case UiOfferType.Energy:
                        this.sendEventForTransactionState(event, { currentService: ServiceType.EnergyNewContract });
                        break;
                }
            }
        });
    }
}
